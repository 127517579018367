import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const Input = ({ className, outline, placeholder, id, name, error, onChange }) => {

	const inputRef = useRef()
	const [hover, setHover] = useState(false)
	const [active, setActive] = useState(false)
	const [complete, setComplete] = useState(false)
	const [color, setColor] = useState(undefined)

	const returnState = () => {
		if (inputRef.current.value.length > 0) {
			setActive(false)
			setComplete(true)
		} else {
			setActive(false)
			setComplete(false)
		}
	}

	const returnActive = () => {
		setActive(true)
		setComplete(false)
	}

	useEffect(() => {
		if(hover && !active){
			if(outline) {
				setColor('var(--teal)')
			} else {
				setColor('var(--light-grey)')
			}
		} else if (active) {
			setColor('var(--bteal)')
		} else if (error?.length > 0) {
			setColor('var(--orange)')
		} else if (complete) {
			setColor('var(--dteal60)')
		}
		else {
			setColor(outline ? 'var(--dteal)' : 'transparent')
		}
	}, [hover, active, complete, error])

	const captionBackground = ()=> {
		if (complete) {
			return true
		} else if (active && !outline) {
			return true
		} else if (error && !active) {
			return true
		}
	}

	return (
		<Wrap className="input">
			{(active || complete || error) && 
				<Caption
					className='cap'
					css={css`
						transform: ${ (active && outline) ? 'translateX(13px) translateY(50%)' : 'none'};
						bottom: ${ (active && outline) ? '100%' : 'calc(100% + var(--xxxs))'};
						color: ${color};
					`}
				>
					<CaptionText 
						for={name}
						css={css`
							background-color: ${captionBackground() ? 'transparent' : 'var(--beige60)'};
						`}
					>
						{placeholder}
					</CaptionText>
				</Caption>
			}
			<StyledInput 
				ref={inputRef}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				onFocus={() => returnActive(true)}
				onBlur={() => returnState(true)}
				type='text'
				id={id}
				className={`${className} h5`}
				placeholder={placeholder}
				name={name}
				onChange={onChange}
				css={css`
					background-color: ${outline ? 'var(--beige60)' : 'var(--white)'};
					border: ${outline ? '2px solid' : 'none'};
					border-bottom: 2px solid;
					border-color: ${((complete && !hover) && !error)? 'var(--grey)' : color};
					color: var(--dteal);
					::placeholder {
						color: var(--dteal);
					}
			`}
			/>
			{(error?.length > 0 && !active)&& 
				<Error className='b2'>{error}</Error>
			}
		</Wrap>
	)
}

const Wrap = styled.div`
	display: inline-block;
	position: relative;
`
const Caption = styled.div`
	position: absolute;
`
const CaptionText = styled.label`
	padding: 2px 4px;
	display: inline-block;
`
const StyledInput = styled.input`
	height: 60px;
	padding: 0 var(--xs);
	width: 100%;
	box-sizing: border-box;
`

const Error = styled.div`
	margin-top: var(--xxs);
	text-align: right;
	color: var(--orange);
	position: absolute;
	left: 0;
	right: 0;
`

Input.propTypes = {
	className: PropTypes.string,
	secondary: PropTypes.bool,
	label: PropTypes.string,
	outline: PropTypes.bool,
	placeholder: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	error: PropTypes.string,
	bgColor: PropTypes.string,
}

export default Input